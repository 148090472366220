import {storePersistKey} from "./Constant";

export default function saveLocalStorage(store) {
    let key = storePersistKey;
    if (localStorage[key]) {
        store.replaceState(JSON.parse(localStorage[key]))
    }

    store.subscribe((mutation, state) => {
        localStorage[key] = JSON.stringify(state);
    })
    store.dispatch('userStore/getUserDetailInfo');
    store.dispatch('userStore/addVisitNumber');
}
