import {get, post, put} from "../http/http";
import baseUrl from "../http/baseUrl";
import {getLocalToken} from "../tools/storage";
import {Modal} from "view-design";
//用户注册账号
export const userRegisterAccount = (data) => {
  return post(`${baseUrl}/v1/register`, data);
}

//其他验证码
export const getVerifyCodeByPhone = (params) => {
  return get(`${baseUrl}/v1/verifyCode`, params);
}
//用户注册获取验证码
export const getExtraVerifyCodeByPhone = (params) => {
  return get(`${baseUrl}/v1/registerCode`, params);
}
//用户登录
export const userPasswordLogin = (data) => {
  return post(`${baseUrl}/v1/login`, data);
}

export const weChatCode  = (params) => {
  return get(`${baseUrl}/v1/bindingCode`, params);
}

//用户手机验证码登录
export const userSmsLogin = (data) => {
  return post(`${baseUrl}/v1/login/code`, data);
}

//重置密码
export const userResetPassword = (data) => {
  return put(`${baseUrl}/v1/user/forgetpwd`, data);
}

//根据用户ID获取用户信息详情
export const getUserDetailById = (params) => {
  return get(`${baseUrl}/v1/user/detail`, params, {hideLoading: true});
}

//检查登录
export const checkLogin = (router) => {
  if (!getLocalToken()) {
      router.push({name: 'login'})
  } else {
    return true
  }
}

//微信登录获取access_token
export const wxLoginGetAccessToken = (params) => {
  return get(`${baseUrl}/third/getAccessToken`, params, {hideLoading: true});
}

//微信登录绑定手机号
export const wxLoginBindPhone = (params) => {
  return post(`${baseUrl}/third/bindUserPhone`, params);
}
