<template>
  <div id="app">
<!--    <div class="app-fixed" style="height: 160px">-->
<!--      <div class="af-body" @click="goSellerCenter">-->
<!--        <img-->
<!--            src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/yunyingguanli%402x.png"-->
<!--            width="16" height="16"/>-->
<!--        <span class="afb-text">机构管理系统</span>-->
<!--      </div>-->
<!--      <div class="af-body" @click="goadminCenter">-->
<!--        <Icon type="md-desktop" style="color: #FFFFFF;font-size: 16px"/>-->
<!--        <span class="afb-text">后台系统</span>-->
<!--      </div>-->
<!--      <div class="af-body" @click="openZoosUrl">-->
<!--        <Icon type="md-headset" style="color: #FFFFFF;font-size: 18px"/>-->
<!--        <span class="afb-text">联系客服</span>-->
<!--      </div>-->
<!--      <Poptip placement="left" trigger="hover">-->
<!--        <div slot="content">-->
<!--          <img :src="erwei" width="100" height="100"/>-->
<!--        </div>-->
<!--        <div class="af-body" style="border: none">-->
<!--          <Icon type="ios-chatbubbles" style="color: #FFFFFF;font-size: 18px"/>-->
<!--          <span class="afb-text">微信公众号</span>-->
<!--        </div>-->
<!--      </Poptip>-->
<!--    </div>-->
    <router-view/>
  </div>
</template>

<script>

import axios from "axios";
import baseUrl from "./plugins/http/baseUrl";

export default {
  data() {
    return {
      erwei: "",
    };
  },
  mounted() {
    axios.get(`${baseUrl}/v1/site/one`).then(res => {
      console.log(res)
      if (res.status === 200) {
        let data = res.data.result;
        document.title = data.title;
        this.erwei = data.qrCode;
      }
    });
  },
  methods: {
    goadminCenter() {
      window.open("http://admin.lygstrim.com/login");
    },
    goSellerCenter() {
      window.open("http://seller.lygstrim.com/broker-dist/user/login");
    },
    openZoosUrl() {
      openZoosUrl("chatwin");
    },
  }
}

</script>

<style>
.app-fixed {
  position: fixed;
  top: calc((100vh - 160px) / 2);
  right: 0;
  z-index: 10000;
  cursor: pointer;
  width: 150px;
  background: linear-gradient(90deg, #2A99DF, #2973E5);
  border-radius: 6px 0 0 6px;
  display: flex;
  flex-direction: column;
}

.af-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-left: 15px;
  border-bottom: 1px solid #3DA1E2;
}

.afb-text {
  font-size: 14px;
  margin-left: 12px;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
