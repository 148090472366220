const wxAppId = 'wx87766ae15b9f49aa';
const lygWxAppId = 'wxdcafef2b04240d79'
const wxCssStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Cg=='
const storePersistKey = `_zg_cli_${process.env.NODE_ENV}_state`.toUpperCase();
export {
  wxCssStyle,
  wxAppId,
  storePersistKey,
  lygWxAppId
}
