<template>
  <div>
  <div class="common_header">
    <div class="header_text" style="display: flex;align-items: center;float: left;position: relative">
        <!-- <div class="platform" style="margin-right: 10px">
          <span>{{ siteTitle }}</span>
        </div>
        <div class="second_border" style="margin-left: 10px;margin-right: 10px"></div> -->
        <div class="zxsc">
                <span class="zxsc_span" style="font-family:'fzhj'!important;">
                    从此，创新走向市场！
                </span>
      </div>
    </div>
    <div class="tab_login_or_not">
      <span @click="goSellerCenter">
        <img
            src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/yunyingguanli%402x.png"
            width="16" height="16"/>
        <span class="afb-text">机构管理系统</span>
      </span>
      <span @click="goadminCenter">
        <Icon type="md-desktop" style="color: #FFFFFF;font-size: 16px"/>
        <span class="afb-text">后台系统</span>
      </span>
      <span @click="openZoosUrl">
        <Icon type="md-headset" style="color: #FFFFFF;font-size: 18px"/>
        <span class="afb-text">联系客服</span>
      </span>
      <span @mouseleave="erweiFlag=false" @mouseover="erweiFlag=true" style="position: relative">
          <Icon type="ios-chatbubbles" style="color: #FFFFFF;font-size: 18px"/>
          <span>微信公众号</span>
          <img v-show="erweiFlag" :src="erwei" alt="" style="height: 100px;width: 100px;position: absolute;top:40px;left: -10px">
      </span>
<!--      <span v-for="(item, index) in tabList" :key="index" @click="goIndex(item)" >{{-->
<!--        item-->
<!--      }}</span>-->
    </div>
  </div>


  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import baseUrl from "../plugins/http/baseUrl";
export default {
  name: "CommonHeader",
  mounted() {
      axios.get(`${baseUrl}/v1/site/one`).then(res => {
        if(res.status == 200){
          let data = res.data.result;
          document.title = data.title;
          this.siteUrl = data.logoImageUrl
          this.siteTitle = data.title
          this.erwei = data.qrCode
        }
      })
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      enterpriseState: "userStore/enterpriseState",
    }),
  },
  data() {
    return {
      siteUrl:'',
      siteTitle:'',
      erweiFlag:false,
      erwei:"",
      footHide:false,
      weModal:false,
      isCer: true,
      tabList: [
        "平台首页",
        // "高校入驻",
        // "区域特色产业平台",
        "联系客服",
        "微信公众号",
        // "关于我们",
      ],
    };
  },
  methods: {
    ...mapMutations({
      setUserToken: "userStore/setUserToken",
      setUserName: "userStore/setUserName",
      setUserAccountType: "userStore/setUserAccountType",
      setUserMbrMemberId: "userStore/setUserMbrMemberId",
      setUserId: "userStore/setUserId",
      setUserEnterpriseState: "userStore/setUserEnterpriseState",
    }),
    logOut() {
      this.setUserToken("");
      this.setUserName("");
      this.setUserAccountType(-1);
      this.setUserMbrMemberId("");
      this.setUserId("");
      this.setUserEnterpriseState("");
      this.$cookies.set("token","",null,null,".lygstrim.com")
      this.$router.push({
        path: "/",
      });
    },
    goRenzheng() {
      this.$router.push({
        path:'/home/EnterpriseCertification'
      });
    },
    goIndex() {
      this.$router.push({
        path: "/",

      })
    },
    goadminCenter() {
      window.open("http://admin.lygstrim.com/login");
    },
    goSellerCenter() {
      window.open("http://seller.lygstrim.com/broker-dist/user/login");
    },
    openZoosUrl() {
      openZoosUrl("chatwin");
    },
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1400px) {
  .header_text {
    display: none !important;
  }
}
.second_border {
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: rgb(127, 127, 127);
}

.wxQrcodePay {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px !important;
    height: 200px !important;
  }
}

.common_header {
  height: 41px;
  padding: 14px 0;
  line-height: 41px;
  background-color: #222222;
  width: 100%;
  min-width: 1300px;
  color:#fff;
}
.header_text {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.tab_login_or_not {
  padding-right: 31px;
  height: 13px;
  line-height: 13px;
  font-size: 13px;
  float: right;
  >span {
    display: inline-flex;
    margin-left: 34px;
    height: 13px;
    cursor: pointer;
    align-items: center;
    span {
      margin-left: 8px;
    }
  }
}
</style>
