let baseUrl;
//'development1' 开发版本 'production'  生产版本
if (process.env.NODE_ENV === 'development1') {
  baseUrl = 'http://trade.lygstrim.com/portal';//服务器地址前缀
  // baseUrl = 'http://192.168.20.83:8101';//服务器地址前缀

} else {
  baseUrl = 'http://trade.lygstrim.com/portal';//服务器地址前缀

}


export default baseUrl;


export const qxtUrl = process.env.NODE_ENV === 'development1'? 'http://192.168.20.83:8101':'http://qxt.lygstrim.com/portal'
export const patentUrl = process.env.NODE_ENV === 'development1'? 'http://192.168.20.83:8101':'http://service.lygstrim.com/portal'
export const examUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:6100':'http://service.lygstrim.com/portal'
export const policyUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://shenbao.lygstrim.com/portal'
export const imgUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://trade.lygstrim.com/portal'
export const certificationUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://qxt.lygstrim.com/portal'
export const indexUrl = process.env.NODE_ENV === 'development1' ? 'http://192.168.20.83:8101': 'http://cms.lygstrim.com'
export const storebaseUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://data.lygstrim.com'
export const resourceUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://data.lygstrim.com'
export const nianfeiUrl = process.env.NODE_ENV === 'development1'?'http://192.168.20.83:8101':'http://service.lygstrim.com/portal'
