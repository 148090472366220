import {storePersistKey} from "./Constant";

export function getLocalToken() {
  let token = ""
  let data = document.cookie.split(";")
  for(let i=0;i<data.length;i++){
    let res = data[i].split("=")
    if(res[0].trim() == "token"){
      token = res[1]
      break;
    }
  }
  if (localStorage[storePersistKey] && token != '') {
    if(JSON.parse(localStorage[storePersistKey]) != undefined){
      const data = JSON.parse(localStorage[storePersistKey]);
      if (data.userStore && data.userStore.token && data.userStore.token.length > 0) {
        return data.userStore.token;
      }
    }

  }
  return '';
}
