import {Message} from "view-design";
import baseUrl, {indexUrl} from "../http/baseUrl";
import axios from "axios";


//全局警告提示
function warnToast(content) {
    Message['warning']({
        background: true,
        content: content
    })
}

//全局成功提示
function successToast(content) {
    Message['success']({
        background: true,
        content: content
    })
}

//全局错误提示
function errorToast(content) {
    Message['error']({
        background: true,
        content: content
    })
}

//全局提示信息
function infoToast(content) {
    Message['info']({
        background: true,
        content: content
    })
}

//验证短信验证码为四位纯数字
function isVerifyCode(code) {
    const reg = /^\d{6}$/;
    return reg.test(code);

}

//验证手机号为11位纯数字
function isPhoneNumber(phone) {
    const reg = /^\d{11}$/;
    return reg.test(phone);
}

//验证密码为8-16位数字字母组合
function isCorrectPassword(password) {
    const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
    return pwdReg.test(password)
}

//数字小鱼9999显示数字大于显示xx.x万
function formatNum(num) {
    if (!num || num === 0) {
        return 0;
    }
    return num > 9999 ? (num / 10000).toFixed(2) + '万' : num
}

//打开全新的页面
function openNewWindow(url) {
    window.open(url, '_blank');
}

//请求时有的接口可以允许同时请求两次
function allowSameRequest(config) {
    const allowUrlA = `${indexUrl}/v1/template/index`;//首页请求
    return config.url === allowUrlA;
}

// 校验正整数
function isNumber(val){
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    if(regPos.test(val)){
        return true;
    }else{
        return false;
    }

}

export {
    isNumber,
    warnToast,
    isVerifyCode,
    isPhoneNumber,
    isCorrectPassword,
    successToast,
    errorToast,
    infoToast,
    formatNum,
    openNewWindow,
    allowSameRequest
}
