import baseUrl, { examUrl, indexUrl, resourceUrl } from "../http/baseUrl";
import {get} from "../http/http";

//首页获取楼层分层展示数据
export const getIndexFloorDetail = (params) => {
  return get(`${indexUrl}/v1/template/index`, params, {hideLoading: true});
}

//获取首页需求大厅数据
export const getIndexRequirementData = (params) => {
  return get(`${baseUrl}/v1/index/requirement`, params, {hideLoading: true});
}

//获取首页头条资讯列表
export const getIndexInformationList = (params) => {
  return get(`${baseUrl}/v1/index/article/list`, params, {hideLoading: true})
}

//获取首页注册部分数据
export const getIndexRegisterData = (params) => {
  return get(`${baseUrl}/v1/index/head `, params, {hideLoading: true});
}

//获取首页高校部分数据
export const getIndexCollegeData = (params) => {
  return get(`${resourceUrl}/v1/head/college/count`, params, {hideLoading: true})
}

//首页底部跳转接口
export const getIndexUrl = (params) => {
  return get(`${indexUrl}/v1/friendlink/index`, params, {hideLoading: true});
}

//增加访问量
export const addVisitNum=params=>{
  return get(`${examUrl}/user/visit`, params, {hideLoading: true});
}
